.tripLabel {
  font-weight: bold;
}

p {
  margin-bottom: 5px;
}

.showTodos {
  display: block;
}

.hideTodos {
  display: none;
}

.guest-image {
  border-radius: 5px;
  height: 35px;
  width: 35px;
  
}
.guest-image:hover {
  height: 200px;
  width: auto;
  position: fixed;
  top: 0;
  z-index: 100;
  
}

.kn-selection_status__selection_status___1FzyX {
  height: 40px !important;
}
.tripCreatorImage {
  border-radius: 10px;
  height: 100px;
  width: 100px;
}

.tripPhotos {
  width: 99%;
  margin: 15px;
}
.trip {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(34, 109, 93);
}

.tripItemDetails {
  border-bottom: 1px solid rgb(34, 109, 93);
  background-color: white;
}
.tripDelete {
  float: right;
  margin: 0;
  position: absolute;
  right: 15px;
  z-index: 1;
  background-color: red;
}

.tripDelete .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(245, 190, 190);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 2px;

  /* Position the tooltip */
  position: absolute;
  top: 0;
  right: 2rem;
  z-index: 1;
}

.tripDelete:hover .tooltiptext {
  visibility: visible;
}
.trip-item__label {
  font-weight: bold;
  color: rgb(48, 59, 55);
  width: 200px;
}
