

#photoDiv {
    border-bottom: 1px solid black;   
    border-top: 1px solid black;  
    margin-bottom: 15px; 
}

.photoDelete{
    float:right;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
}
.photoBy{
  margin: 0;
  position: absolute;
  top: 0;
  right: 2rem; 
  font-weight: 700; 
}

.photoImage {
    margin-top:calc(2rem - 2px);
    height: auto;
    max-width: 100%;
    width: 100%;
}
.display-caption{
  font-weight: 500;
  font-style: italic;
  text-align: center; 
}
.display-tags{
  position: absolute;
  top: .5rem;
  left: 1rem;
  font-weight: 500;
  font-style: italic;
  margin-right: 1rem;
}
.photoCaption {
  text-align: center;
}

.photoCaption label{
  font-weight: bold;
  padding : 5px;
}

.photoCaption input{
   width:50%
}

.photoDelete .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color:  rgb(245, 190, 190);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 2px;

  /* Position the tooltip */
  position: absolute;
  top: 0;
  right: 2rem;
  z-index: 1;
}

.photoDelete:hover .tooltiptext {
  visibility: visible;
}
  
  .dropdown {
    position: relative;
    float: right;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    bottom: 0;
    background-color: lightblue;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 0;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid black; 
  }
  
  .dropdown-content a:hover {background-color:transparent;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: transparent;}