.calendar-container,
.calendar,
.calendar-header,
td,
tr {
    padding: 0;
    margin: 0;
}

.calendar-container {
    box-shadow: 2px 2px 8px rgba(34, 109, 93, 1);
    position: relative;
    width: 100%;
}

.calendar {
    background-color: white;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

.calendar-header {
    border-bottom: 3px solid rgb(34, 109, 93);
    font-size: 1.5em;
    background-color: rgb(200, 248, 232);
    
}

.calendar-header td {
    border-spacing: 0;
    padding-left: 15px;
}

.day,
.empty-slot,
.week-day {
    
    vertical-align: top;
    height: 40px;
    padding-left: 5px;
    border: 1px solid burlywood;
}

.week-day {
    text-align: center;
    font-size: 1.5em;

}

.day {
    text-align: left;
    font-size: 0.8em;
    border: 1px solid burlywood;
}

.day {
    cursor: pointer;
    width: 94px;
    height: 94px;
}
.dayHolder {
    position: relative;
}
.current-day {
    background-color: #c9ddf7;
    border-radius: 2%;
    color: black;
}
.selected-day {
    border: 3px solid rgb(34, 109, 93);
    border-radius: 2%;
    color: rgb(34, 109, 93);
}
.dayOfMonth{
    position: absolute;
    top: 5px;
    left: 15px;
    font-size: 5em;
    opacity: .15;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; 
        
}

@media (max-width: 768px) {
    .dayOfMonth {
        position: static;
        font-size: 1em;
        opacity: 1;
    }
  }
.month-popup {
    position: absolute;
    padding: 5px;
    z-index: 2;
    border: 2px solid burlywood;

}

.month-popup div {
    padding: 0.1em;
}

.nav-month {
    
    float: right;
    font-size: 1/5em;
    cursor: pointer;

}

.prev {
    color: rgb(34, 109, 93);
}

.next {
    color: rgb(34, 109, 93);
}

.editor-year {
    max-width: 3.6em;
}

.label-month,
.label-year {
    font-size: 0.7em;
    color: rgb(34, 109, 93);
}
.label-month div {
    font-size: .9em;
    background-color: rgb(227, 245, 239);
    box-shadow: 2px 2px 8px rgba(34, 109, 93, 1);
    color: black;
}
.label-month div:hover {
    font-size: .9em;
    background-color: rgb(171, 228, 209);
    
}
.label-month {
    cursor: pointer;
}
