.activityLog-item {
  margin: 0;
  width: 100%;
  font-weight: 100;
  border-bottom: dotted rgb(34, 109,93) 1px;
  
}
.activityLog-item a {
  color: transparent;
}

.activityLog-item__label {
  padding-right: 2px;
  padding-left: 1rem;
  font-weight: 500;
  color: black;
 
}

.activityLog-item__content {
  padding: 0;
}
.activityLog-item__image_right {
  float: right;
}

.datePicker_input {
  width: 8rem;
}

.activityLog-item:hover h2,
.activityLog-item:active h2,
.activityLog-item:hover h3,
.activityLog-item:active h3 {
  color: #292929;
}

.activityLog-item__info h3 {
  margin: 0;
}
