  
  .itemGroup {
    display: flex;
    background: hsl(var(--accent) / .1);
    margin-bottom: 6px;
    font-style: inherit;
    padding-right: 10px;
    font-weight: 500;
  }

  .itemFormGroup {
    display: flex;
    margin-bottom: 6px;
    font-weight: 500;
  }
  
  .label {
    position: relative;
    cursor: pointer;
    text-align: left;
    line-height: 1.4;
    padding-left: 8px;
    margin-bottom: 0;
  }
  
  .checkbox {
    flex-shrink: 0;
    appearance: none;
    -webkit-appearance: none;
    border-radius: var(--_radius);
    width: 1em;
    height: 1em;
    background-color: hsl(var(--muted));
    border-radius: var(--_radius);
    box-shadow:
      0 0 .5em hsl(var(--accent) / .1),
      0 0 0 0.05em hsl(var(--accent) / .5),
      0 0 0 -0.2em hsl(var(--accent));
    transition: box-shadow var(--_tspeed_fast) ease-in-out,
      background-color 80ms ease-in-out;
  }
  
  .checkbox:focus {
    outline: none;
  }
  
  .checkbox:is(:focus-visible, :hover) {
    box-shadow:
      0 0 0 hsl(var(--bg)),
      0 0 0 .05em hsl(var(--accent)),
      0 0 0 .225em hsl(var(--accent) / .3);
  }
  
  .checkmark {
    content: '';
    position: absolute;
    width: 1em;
    height: 1em;
    display: grid;
    place-items: center;
    top: .1em;
    left:-1em;
    color: hsl(var(--muted));
    border-radius: var(--_radius);
    transform: translate3d(calc(-100% - var(--itemgroup-gap)), -50%, 0);
    transition: background-color 80ms ease-in-out;
  }
  
  .checkmark svg {
    width: .75em;
    height: .75em;
  }
  
  .checkbox:checked {
    background-color: hsl(var(--accent));
  }
  
  .checkbox:checked+label {
    text-decoration: line-through;
  }
  .lineThrough{
    text-decoration: line-through;
  }
  
  .delete {
    --accent: var(--accent1);
  }
  
  .loading {
    color: hsl(var(--accent));
    font-size: var(--_size);
    font-weight: bold;
    animation: pulse 2s infinite ease-in-out;
  }
  /* NewItemForm EditItemForm */
.wrapper {
  position: relative;
  display: grid;
  gap: 0.5em;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  box-shadow: 0 2px 2px .5px hsl(var(--accent));
}
.todoWrapper {
  grid-template-columns: repeat(12, 1fr) ;
  grid-template-areas: 'listName listName listName listName listName listNote listNote listNote listNote listNote listNote listAdd'  ;
}

.shoppingWrapper {
  grid-template-columns: repeat(12, 1fr) ;
  grid-template-areas: 'listName listName listName listName listNote listNote listNote listNote listNote listQty listQty listAdd'  ;
}
.listName {
 grid-area: listName
}
.listNote {
  grid-area: listNote
 }
 .listQty {
  grid-area: listQty
 }
 .listAdd {
  grid-area: listAdd;
  padding: 0 2em;
 }

@media screen and (max-width: 768px) {
  
  .todoWrapper {
    grid-template-columns: repeat(4, 1fr) ;
    grid-template-areas: 'listName listName listName listName ' 'listNote listNote listNote listNote' '. . listAdd .'  ;
  }
  
  .shoppingWrapper {
    grid-template-columns: repeat(6, 1fr) ;
    grid-template-areas: 'listName listName listName listName listName listName' 'listNote listNote listNote listNote listNote listNote' 'listQty listQty listAdd listAdd listAdd listAdd'  ;
  }
}
  @keyframes pulse {
  
    0%,
    100% {
      opacity: 1;
    }
  
    50% {
      opacity: 0.5;
    }
  }
  
  .error {
    color: hsl(var(--accent1));
    font-size: var(--_size);
    text-align: center;
  }