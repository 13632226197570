.user-item {
  margin: 1rem;
  width: calc(33% - 2rem);
  min-width: 30rem;
  background-color: cornsilk;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  position: relative;
}



.btnDiv {
  
  bottom: 0;
  position: absolute;
}

.user-item-edit {
  text-align: center;
  
}

.user-item__content {
  padding: 0;
  
}
.user-item__image_right {
  float: right;
}
.user-item__image {
  border: 1px solid rgb(44, 65, 56);
  margin-top:calc(2rem - 2px);
  height: auto;
  max-width: 100%;
  width:13rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: black;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}