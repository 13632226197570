.trip-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  max-width: 50rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.tripEditCommands {
  text-align: center;
}


.trip-guest-header {
  width: 100%;
  padding: 1rem 0.5rem;
  color: rgb(48, 59, 55);
  background-color: rgb(200, 248, 232);
  text-align: center;
  font-weight: bold;
  border: 1px solid rgb(34, 109, 93);
}