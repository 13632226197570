.listItems {
  
  list-style-type: none;
  gap: .1em;
  min-width: 20em;
  font-size: clamp(1rem, 2.5vw, 2rem);
  box-shadow: 0 -1em 3em hsl(var(--muted)), 0 1em 3em hsl(var(--accent) / 0.2);
}

.todoGrid{
  display: grid;
  grid-template-columns: 15fr 15fr 1fr 1fr;
}
.shoppingGrid{
  display: grid;
  grid-template-columns: 15fr 1fr 15fr 1fr 1fr;
}