.users-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
}

.user-trip-list {
  list-style: none;
  padding: 0 0 5px 5px;
}

.planned-trips h2 {
font-weight: bold;
}

.scrollable {
  height: 100px;
  width: 99%;
  overflow-y: auto;
  border: 1px solid lightblue;
  padding: 0px;

}
.bigMessageContainer {
  height: 500px;
  width: 100%;
}
.bigMessage {
 
  border: 5px solid black;
  padding: 50px;
  color: red;
  font-size: 400%;
  font-weight: 900;
  text-align: center;
}
.bigMessageCounter {
  color: black;
  font-size: 200%;
  font-weight: 500;
  text-align: center;
}