.my-tooltip {
  position: relative;
}

.my-tooltip-trigger {
  display: inline-block;
}

.my-tooltip-bubble {
  min-width: 250px;
  max-width: 250px;
  position: absolute;
  z-index: 10;
}

.my-tooltip-bubble::after {
  content: "";
  position: absolute;
}

.my-tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);
}

.my-tooltip-top::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.my-tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
}
.my-tooltip-bottom::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid rgba(0, 0, 0, 0.7);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.my-tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);
}

.my-tooltip-left::after {
  border-left: 9px solid rgba(0, 0, 0, 0.7);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.my-tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);
}
.my-tooltip-right::after {
  border-right: 9px solid rgba(0, 0, 0, 0.7);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.my-tooltip-message {
  background-color: rgb(200, 248, 232);
  border-radius: 3px;
  color: rgb(48, 59, 55);
  font-size: 1.25rem;
  line-height: 1.4;
  padding: 0.75em;
  text-align: center;
}
