.comment {
    border: 1px solid gray;
    padding: 0px;
    margin-left: 0px;
    background-color: lavender;
    padding-left: -15px;
    width: 100%;

}

.reply {
    font-size: .8em;
    padding: 0px;
   
    text-align: left;
   
}
.time {
    display: none;
}
span:hover .time{
    display: inline;
    
}

#commentDiv {
    border-bottom: 1px solid black;  
   
}
.createdBy {
    font-size: .8em;
    color: mediumblue;
}
.singleComment{
    background-color:#dee2e6;
    

}

.urlIcon {
    height:20px;
    width: 20px;
    margin-right: 5px;
    border-radius: 50%;
}
.largerImage {
    height: 30px;
    width: 30px;
}