body {
  background-color: #e6ecf0;
}

.coronaSign {
  position: absolute;
  animation: 3s linear 0s forwards sun-rise;
  border: yellow solid 10rem;
}

@keyframes sun-rise {
  0% {
    /* pushes the sun down past the viewport */
    scale: 2%;
    left: -10%;
    top: 5%; 
    opacity: .2;  
  }
  85% {
      scale: 10%;
      transform: rotate(180000deg);
      border: yellow solid .1rem;
      }
  100% {
    /* returns the sun to its default position */
    transform: rotate(18000deg);
    scale: 70%;
    left: 30vw;
    top:65vh;
    border: yellow solid .1rem;
    opacity: .5;
  }
}

@media screen and (max-width: 768px) {

  
@keyframes sun-rise {
  0% {
    /* pushes the sun down past the viewport */
    scale: 10%;
    top: 0;
    opacity: .2;
  }
  
  95% {
      scale: 20%;
      transform: rotate(18000deg);
      border: yellow solid .1rem;
      opacity: 1;
      }
  100% {
    /* returns the sun to its default position */
    transform: rotate(18000deg);
    scale: 20%;
    top:45vh;
    border: yellow solid .1rem;
    opacity: .3;
  }
}
}

.home-hero {
  box-sizing: border-box;
  width: 100vw;
  height: 95vh;
  position: absolute;
  top: 20;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./images/corona-sunset.JPG");
  padding: 0rem;
  background-position: center center;
  background-size:cover;
  color: rgb(200, 248, 232);
  text-shadow: 4px 4px 2px rgb(14, 12, 12);
}


.center {
  text-align: center;
}


.btn:hover {
  cursor: pointer;
}

a.btn.btn-danger,
a.btn.btn-danger:hover {
  color: white;
}

.navbar-header {
  margin: 0 auto;
}



.homepage-container {
  border: 2px solid black;
  display: flex;
  justify-content: space-between;
}
.homepage-left {
  flex: 1;
  background-color: rgb(200, 200, 200);
  align-items: center;
}
.homepage-center {
  flex: 3;
}
.homepage-right {
  flex: 2;
  background-color: rgb(200, 200, 200);
}
.bg-secondary {
  background-color: rgb(200, 200, 200);
}
.link {
  color: #385898;
}
.link:hover {
  text-decoration: underline;
  color: mediumblue;
}

.userImage {
  border-radius: 10px;
  height: 35px;
  width: 35px;
}
.weather {
  border-radius: 10px;
  position: fixed;
  scale: 90%;
  left: 80vw;
  top:10vh;
  opacity: 0;

  animation: 4s linear 3s forwards show-weather;
}

@keyframes show-weather {
  from { opacity: 0; }
  to { opacity: .7; }
}


@media screen and (max-width: 768px) {
  .weather {
    scale: 60%;
    left: auto;
    top:1vh;
  }
}

.addButtons {
  width: 150px;
  text-align: center;
}
.btn-primary {
  color: rgb(48, 59, 55);
  background-color: rgb(183, 223, 209);
  font-weight: 500;
  border: 1px solid rgb(34, 109, 93);
  text-shadow: 0 0 0 rgb(14, 12, 12);
}
.btn-primary:hover {
  color: rgb(200, 248, 232);
  background-color: rgb(34, 109, 93);
  font-weight: bold;
}
.btn-primary:disabled {
  color: rgb(180, 197, 191);
  background-color: rgb(200, 248, 232);
  font-weight: bold;
  border: 1px solid rgb(34, 109, 93);
}
.btn-primary:disabled:hover {
  color: rgb(200, 248, 232);
  background-color: rgb(34, 109, 93);
  font-weight: bold;
}

.common-header {
  width: 100%;
  padding: .2rem 0.2rem;
  color: rgb(34, 109, 93);
  background-color: rgb(200, 248, 232);
  text-align: center;
  font-size: 2.0rem;
  border-bottom: 2px solid rgb(34, 109, 93);
  font-weight: 600;  
}
@media screen and (max-width: 768px) {
  .common-header {
    font-size: 1.5rem;
  }
}

div.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 55px;
   z-index: 1;
  border-left: 1px solid rgb(34, 109, 93);
  border-right: 1px solid rgb(34, 109, 93);
  border-bottom: 5px solid rgb(34, 109, 93);
  background-color: white;
}
.container-fluid {
padding-right: 0px;
padding-left: 0px;
}