.activityLogs-list {

  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.activityLog-trip-list {
  list-style: none;
  padding: 0 0 5px 5px;
}

.planned-trips h2 {
  font-weight: bold;
}

.scrollable {
  height: 100px;
  width: 99%;
  overflow-y: auto;
  border: 1px solid lightblue;
  padding: 0px;
}

.activityHeading {
  border-top: 2px solid rgb(103, 128, 119);
  background-color: rgb(217, 235, 228);

  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(48, 59, 55);
}
.activityHeading span {
  margin-right: 25px;
}
.activity-title {
  border-bottom: 2px solid rgb(103, 128, 119);
  height: 100px;
  margin: 0px;
  padding-top: 20px;
  vertical-align: middle;
  color: rgb(48, 59, 55);
  background-color: rgb(200, 248, 232);
}
.activity-list {
  border-top: 1px solid rgb(103, 128, 119);
  padding-top: 10px;
}
