  

  .itemFormGroup {
    display: flex;
    margin-bottom: 6px;
    font-weight: 500;
  }
  button{
    font-size: x-large;
  }
  textarea{
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
    text-align: left;
    line-height: 1.4;
    padding-left: 8px;
    margin-bottom: 0;
    font-weight: 700;
  }
  .centerChild {
    display: grid;
    place-items: center;
    margin-top: 0rem;  
    padding: .5rem;
  }

  .editButtons{
    padding-bottom: 2px;
    border-bottom: 1px solid black;
  }
  .multiSelectWrapper{
    color:black;
    font-weight: 500;
  }
  .modalWrapper{
    top:2vh;
    height: 97vh;
  }
  /* NewItemForm EditItemForm */
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  box-shadow: 0 2px 2px .5px hsl(var(--accent));
}
.emailControl {
  width: 90%;
  padding-left: .2em;
}

.recipientList {
  display:flex;
  gap: 2rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}
.displayEmails{
  display:flex;

}
.modalEmail{
position: relative;
}



  
 