.photos-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid rgba(0, 0, 0, 0.26);
}

.form-control {
  margin: 0;
 padding: 0;
  border: 0px solid white;
}
.photo-trip-list {
  list-style: none;
  padding: 0 0 5px 5px;
}
.filterSettingLabel,
.planned-trips h2 {
  font-weight: bold;
}


.scrollable {
  height: 100px;
  width: 99%;
  overflow-y: auto;
  border: 1px solid lightblue;
  padding: 0px;
}
.tripHeading {
  border-top: 2px solid rgb(103, 128, 119);
  border-bottom: 1px solid rgb(103, 128, 119);
  background-color: rgb(217, 235, 228);
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: rgb(48, 59, 55);
  text-align: center;
}
.tripHeading span {
  margin-right: 25px;
}
.trip-title {
  border-bottom: 2px solid rgb(103, 128, 119);

  margin: 0px;
  padding-top: 5px;
  padding-bottom: 10px;
  vertical-align: middle;
  color: rgb(48, 59, 55);
  background-color: rgb(200, 248, 232);
  font-weight: 500;
}
.filterCriteriaHeading{
font-size: 1.2rem;
font-weight: 500;
}
.filterCriteria {
  background-color: rgb(188, 212, 205);
  font-weight: 400;
}
.photoSizeControl {
  
  padding-top: 20px;
  position: absolute;
  top: 0;
}
.slider {
  border-top: 1px solid rgb(103, 128, 119);
  width: 150px;
}
@media all and (max-width: 992px) {
  .slider-component {
    visibility: hidden;
  }
}
