
.modalWrapper{
  max-width:fit-content;
  left: 30%;
  margin:1rem;
}

.list-group {
  display: flex;
  align-items: center;
  background: hsl(var(--accent) / .1);
  margin-bottom: 5px;
  font-style: inherit;
  padding-left: 10px;
  font-weight: 500;
  background-color: hsl(var(--muted));
}
.reminderCollection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin:0 3px;
}

.reminderItems {
    display: grid;
    grid-template-columns: 8fr 2fr 1fr 1fr;
    list-style-type: none;
    font-size: clamp(1rem, 2.5vh, 2rem);
    justify-content: center;
    align-items: center;
    border: 2px solid hsl(var(--accent) / .5);
    background: hsl(var(--accent) / .1);
    padding-right: 5px;
  } 

  .listType {
    font-weight: bold;
    column-gap: 10px;
    display: flex;
    flex-direction: column;
  }

  header h2{
    margin: 0;
  }
    
  .reminderItem {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    font-style: inherit;
    padding-left: 10px;
    font-weight: 500;
  }

  .newListWrapper {  
    position: relative;
    display:flex;
    flex-direction: column;
    gap: 0.5em;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    box-shadow: 0 2px 2px .5px hsl(var(--accent));
    max-width:fit-content;
    align-items: center;
  }

  @media (max-width: 1200px) {
    .reminderCollection {
      grid-template-columns: 1fr;
      max-width: 100%;
    }
    .reminderLists {    
      margin-inline:0;
    }

    .modalWrapper{
      max-width: 100%;
      left: 5px;
      margin-right:2rem;
    }

    .newListWrapper {
      grid-template-columns: 1fr;
    }

    .reminderItems {
      grid-template-columns: 6fr 3fr 1fr 1fr;
      overflow: scroll;
    }
  }