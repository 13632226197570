.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 35rem;
  margin: 7rem auto;
}

.authentication form {
  margin-bottom: 1rem;
}