/* Nav */

.nav-user  {
 
  color: white;
  margin-left: 10px;
}
.navbar a  {
  font-weight: bold;
  color: white;
}
.dropdown button {
  background: transparent;
  border: 0;
  font-weight: bold;
  color: white;
}

.dropdown ul {
  background: rgb(34, 109, 93);
}

.dropdown ul li:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.navbar a:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.navbar-brand > img {
  display: inline-block;
  width: 20px;
  margin-bottom: 2px;
  margin-right: 5px;
  border-radius: 2px;
}

ul.nav.navbar-nav li {
  margin: 0 0.5rem;
}

.onboarding > .navbar {
  background: rgb(34, 109, 93);
  border: 2px solid rgb(48, 59, 55);
}
.nav_top{
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}