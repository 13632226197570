*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* EditITemForm, NewITemForm */

input,
label {
  font: inherit;
}

:root {
  --_hue: 167;
  --_size: 1.8rem;
  --_radius: 0.2em;
  --_tspeed_fast: 180ms;
  --_tspeed_slow: 300ms;
  --_ttype_squish: cubic-bezier(0.86, -0.1, 0.27, 1.15);

  /* Light Mode */
  --bg--light: var(--_hue) 30% 94%;
  --txt--light: var(--_hue) 40% 5%;
  --accent--light: var(--_hue) 68% 28%;
  --accent1--light: 10 80% 60%;
  --muted--light: var(--_hue) 30% 99%;
  /* Dark Mode */
  --bg--dark: var(--_hue) 15% 10%;
  --txt--dark: var(--_hue) 30% 88%;
  --accent--dark: var(--_hue) 50% 50%;
  --accent1--dark: 10 55% 50%;
  --muted--dark: var(--_hue) 20% 4%;

  --bg: var(--bg--light);
    --txt: var(--txt--light);
    --accent: var(--accent--light);
    --accent1: var(--accent1--light);
    --muted: var(--muted--light);

}
input[type="radio"]{
  margin: 0 10px 0 10px;
}
.noWrap {
  white-space: nowrap;  
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  :root {
    --_size: 1.3rem;
  }
}

/* prefers reduced motion */
@media (prefers-reduced-motion: reduce) {
  :root {
    --_tspeed_slow: 50ms;
    --_tspeed_fast: 50ms;
  }
}

::selection {
  background: hsl(var(--accent) / 0.8);
  color: hsl(var(--bg));
}

/* ListWrapper */
h1 {
  font-size: calc(var(--_size) * 1.3);
  text-align: center;
}
/* container */
.container {
  max-width: 100%;
}

/* EditITemForm Item ListCollection ListWrapper NewITemForm NewListModalForm */
.btn {
  max-width: fit-content;
  display: flex;
  place-items: center;
  gap: 0.2rem;
  background-color: hsl(var(--accent));
  color: hsl(var(--bg));
  text-decoration: none;
  padding: 0.2rem;
  text-wrap: nowrap;
  border-radius: var(--_radius);
}

.btn :where(svg, img, span) {
  pointer-events: none;
}

.btn :where(svg, img) {
  width: var(--_size);
  height: var(--_size);
}

.btn:where(:active, :hover) {
  background-color: hsl(var(--accent) / 0.7);
  box-shadow: 0 0 0 hsl(var(--accent) / 0.3), 0 0 0 -0.1em hsl(var(--bg)),
    0 0 0 -0.2em hsl(var(--accent));
}

.btn:focus {
  outline: none;
}

.btn:focus-visible {
  box-shadow: 0 0 0 hsl(var(--accent) / 0.3), 0 0 0 0.2em hsl(var(--bg)),
    0 0 0 0.4em hsl(var(--accent) / 0.7);
}




.label {
  display: grid;
  align-items: center;
  pointer-events: none;
  letter-spacing: 0.05em;
  margin-inline: 0.45em;
  padding: 0.1em 0.35em;
  white-space: nowrap;
  font-weight: 700;
}

.input {
  border: none;
  border-radius: var(--_radius);
  padding: 0.35em 0.55em;
  width: 80%;
  caret-color: hsl(var(--accent));
  box-shadow: 0 0 0 -0.1em hsl(var(--bg)), 0 0 0 -0.2em hsl(var(--accent) / 0.8);
  transition: box-shadow var(--_tspeed_fast) var(--_ttype_squish);
  box-shadow:
  inset .1em 0.1em .1em hsl(var(--accent) / 0.8);
}

.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px  hsl(var(--muted)) inset !important;  
}


.input:focus {
  height: 2em;
  outline: none;
  box-shadow: 0 0 0 0.1em hsl(var(--bg)), 0 0 0 0.3em hsl(var(--accent) / 0.8);
}

.input:focus~.label,
.input:not(:placeholder-shown)~.label {
  transform: translate3d(0, -3em, 0);
  background-color: hsl(var(--bg));
  color: hsl(var(--accent) / 0.8);
}

.input::placeholder {
  opacity: 0;
}

/* NewListModalForm*/
.modalNewList {
  z-index: 100;
  text-align: center;
  background-color: hsl(var(--bg));
  color: hsl(var(--txt));
}

/* ListWrapper, NewListModalForm Auth */
.listItemEditControls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 6px;
    
}


